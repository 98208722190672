@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

:root {
  --sidebar-width: 250px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.vh-75 {
  height: 75vh;
}

.bg-main {
  background-color: #5c98ff;
  outline: none !important;
}

.btn-main {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5c98ff;
  --bs-btn-border-color: #5c98ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2173ff;
  --bs-btn-hover-border-color: #2173ff;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2173ff;
  --bs-btn-active-border-color: #2173ff;
  --bs-btn-active-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #7cabfc;
  --bs-btn-disabled-border-color: #7cabfc;
}

.text-main {
  color: #5c98ff;
}

.accordion-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 0;
  overflow: hidden;
  border-top: 1px solid rgba(0,0,0,0.5) !important;
}

.accordion-item, .accordion-button {
  border-radius: 0 !important;
  font-size: 0.9rem !important;
}

.side-menu {
  background-color: #5c98ff;
  color: #2173ff;
  width: var(--sidebar-width) !important;
  max-width: var(--sidebar-width) !important;
}

.bg-side-menu {
  background-color: #5c98ff !important;
  color: #fff !important;
}

.bg-side-menu.accordion-header button {
  padding: 0.5rem !important;
}

.accordion-item .bg-side-menu,
.accordion-item .bg-side-menu .accordion-button.collapsed,
.accordion-item .bg-side-menu .accordion-button.collapsed::after,
.accordion-item .bg-side-menu .accordion-button,
.accordion-item .bg-side-menu .accordion-button::after,
.accordion-item .bg-side-menu .nav-link
{
  background-color: #5c98ff !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none;
}

.accordion-item .bg-side-menu .accordion-button.collapsed,
.accordion-item .bg-side-menu .accordion-button.collapsed::after
{
  content: none !important;
  background-image: none;
}

.accordion-item .bg-side-menu .accordion-button:not(.collapsed),
.accordion-item .bg-side-menu .accordion-button:not(.collapsed)::after
{
  content: none !important;
  background-color: #2173ff !important;
}

.accordion-item .bg-side-menu .accordion-button:hover,
.accordion-item .bg-side-menu.dashboard:hover
{
  background-color: #4288ff !important;
}

.accordion-item .bg-side-menu .nav-link:hover
{
  background-color: #4288ff !important;
}

.accordion-item .bg-side-menu .nav-link:active
{
  background-color: #0862fc !important;
}

.accordion-item, .accordion-item .accordion-collapse {
  border: none !important;
}

.accordion-body .nav-link:active,
.accordion-body .nav-link.active,
.nav .bg-side-menu.active
{
  background-color: #0862fc !important;
}

.breadcrumb-item.breadcrumb-separator::before {
  content: "|" !important;
  font-weight: bolder;
  font-size: 20px;
  vertical-align: middle;
  text-align: center;
  line-height: 18px;
  color: #2173ff !important;
}

.breadcrumb-title-active {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid;
  border-radius: 5px;
  color: #fff !important;
  background-color: #00b029 !important;
}

.side-menu .title, .side-menu .btn-close {
  color: #fff !important;
}

.btn-sidebar {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2173ff;
  --bs-btn-border-color: #2173ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1a5ac7;
  --bs-btn-hover-border-color: #1a5ac7;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a5ac7;
  --bs-btn-active-border-color: #1a5ac7;
  --bs-btn-active-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4484f2;
  --bs-btn-disabled-border-color: #4484f2;
}

.font-sm-custom, .font-sm-custom .btn i {
  font-size: 0.8rem !important;
}

.bg-grid-header {
  background-color: #eee !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.bg-grid-header tr {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border: 1px solid #000 !important;
}

.bg-grid-header th {
  border: 1px solid #bbb !important;
}

.bg-grid-header .left-corner {
  border-top-left-radius: 5px !important;
}

.bg-grid-header .right-corner {
  border-top-right-radius: 5px !important;
}

.grid-filter {
  border-bottom-color: #555 !important;
}

.grid-filter:focus, .grid-filter:active {
  outline: none !important;
}

.grid-filter::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1; /* Firefox */
  font-style: italic !important;
}

.grid-filter:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999 !important;
  font-style: italic !important;
}

.grid-filter::-ms-input-placeholder { /* Microsoft Edge */
  color: #999 !important;
  font-style: italic !important;
}

.modal-header .btn-close {
  padding: 0 !important;
  margin: 0 !important;
}

.nav-tabs {
  border-bottom-color: #2173ff !important;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

.nav-tabs .nav-link:hover {
  background-color: #ddd !important;
  border-bottom-color: #2173ff !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link:active {
  background-color: #2173ff !important;
  color: #fff !important;
  outline: none !important;
  border-bottom-color: #2173ff !important;
}

.page-footer {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}

.loader {
  z-index: 9999 !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

.loader .modal-dialog, .loader .modal-dialog .modal-content {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.loader .modal-body {
  background-color: rgba(0, 0, 0, 0.25) !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.modal-w-70 {
  width: 70% !important;
  max-width: 100% !important;
}

.modal-w-80 {
  width: 80% !important;
  max-width: 100% !important;
}

.modal-w-90 {
  width: 90% !important;
  max-width: 100% !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #666 !important; 
  border-radius: 0.25rem !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333 !important; 
}

.navbar-nav a, .nav a, .nav-item button, .breadcrumb-item span, .breadcrumb-item a i {
  font-size: 0.9rem !important;
}

.btn-facebook {
  background-color: #4267B2 !important;
  color: #fff !important;
}

.btn-instagram {
  background-color: #e56969 !important;
  color: #fff !important;
}

.bg-light-custom {
  background-color: #fcf6f5 !important;
}

.text-default-custom {
  color: #2173ff !important;
}

.e-ddl.e-input-group.e-control-wrapper .e-input, td input, td textarea {
  font-size: 0.8rem !important;
}

.e-dropdownbase .e-list-item, .e-dropdownbase .e-list-item.e-item-focus {
  font-size: 0.8rem !important;
}

.cursor-pointer, .cursor-pointer > input {
  cursor: pointer;
}

.top--10 {
  top: -10px !important;
}

.print-size-default {
  width: 210mm !important;
}

.print-header,
.print-header-space,
.print-footer,
.print-footer-space {
    height: 212px;
}

.print-header {
    position: fixed; 
    width :100%;
    top: 0;
}

.print-footer {
    position: fixed;
    bottom: 0;
}
.print-invoice {
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 2px;
}

.font-print-sm {
  font-size: 9pt !important;
}

.font-print-title {
  font-size: 12pt !important;
}

.btn-print-download {
  padding: 10pt !important;
  background-color: #2173ff !important;
  color: #fff !important;
  border-radius: 10pt !important;
}

.img-navbar-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.img-preview {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.img-tooltip {
  max-width: 800px !important;
  width: 800px !important;
  height: auto !important;
}

.select-items {
  z-index: 999999999 !important;
  display: block;
}

.img-popup {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  width: 800px;
  height: auto;
}

.img-tooltip .react-transform-wrapper, .img-tooltip .react-transform-wrapper .react-transform-component {
  max-width: 800px !important;
  width: 800px !important;
  height: auto !important;
}

.main-container {
  width: 100%;
  transition: all .3s ease-in-out;
}
.main-container.crop {
  width: calc(100% - var(--sidebar-width));
  transform: translateX(var(--sidebar-width));
}

.menu-card {
  cursor: pointer;
}

.menu-card:hover {
  background-color: #efffee;
}

.menu-card-icon-container {
  margin-top: -50px !important;
  background-color: transparent !important;
}

.menu-card-icon {
  height: 150px !important;
  width: auto !important;
}

.accordion-questions {
  border-bottom: 1px solid #000 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
